
html,
body {
  padding: 0;
  margin: 0;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Popper / MUI Tooltip */
[role="tooltip"] {
  z-index: 1;
}

[data-popper-placement="top"] [class*="Filter-filterListRoot"] {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
